export const ArrayUtil = {
	chunkArray(myArray, chunk_size) {
		let arrayLength = myArray.length;
		let tempArray = [];

		for (let index = 0; index < arrayLength; index += chunk_size) {
			let myChunk = myArray.slice(index, index + chunk_size);
			// Do something if you want with the group
			tempArray.push(myChunk);
		}

		return tempArray;
	},

	removeItem(myArray, itemToRemove) {
		let index = myArray.indexOf(itemToRemove);
		if (index > -1) {
			myArray.splice(index, 1);
		}
		return myArray;
	},

	getIndex(myArray, fieldName, value) {
		let index = -1;
		for (let i = 0; i < myArray.length; i++) {
			if (myArray[i][fieldName] === value) {
				index = i;
				break;
			}
		}
		return index;
	},

	getUniqueValues(arr) {
		arr = arr.reduce((unique, item) => {
            return unique.includes(item) ? unique : [...unique, item];
        }, []);

		return arr;
	}
};
