<template>
	<div class="selected-table">
		<b-row>
			<b-col sm="6" class="mb-1">
				<i class="icon-check"></i>&nbsp;
				<b>SELECTED</b>
			</b-col>
		</b-row>

		<b-row class="my-2">
			<b-col lg="5" md="8" sm="12" class="my-1 text-sm-left">
				<b-button size="sm" @click="selectAllRows">Select all</b-button>&nbsp;&nbsp;
				<b-button size="sm" @click="clearSelected">Clear</b-button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
			</b-col>
			<b-col lg="5" md="8" sm="9" class="my-1 text-sm-left">
				<span class="total-display">Selected: {{ Object.keys(selected).length }}</span>
				&nbsp;&nbsp;|&nbsp;&nbsp;
				<span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
			</b-col>
			<b-col lg="2" md="4" sm="2" class="my-1 text-sm-right">
				<b-button variant="danger" @click="deleteSelectedAssets" v-b-tooltip.hover.top="'Delete Selected Assets'"
					class="mr-1">
					<i class="fa fa-trash"></i>
				</b-button>
			</b-col>
		</b-row>

		<b-row class="mx-1">
			<b-table ref="selectedAssetsTable" show-empty striped hover :items="items" :fields="fields"
				:current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy"
				:sort-desc.sync="sortDesc" :sort-direction="sortDirection" responsive selectable select-mode="multi"
				@row-selected="onRowSelected" selected-variant="primary">
				<template v-slot:cell(selected)="{ rowSelected }">
					<template v-if="rowSelected">
						<span aria-hidden="true">&check;</span>
						<span class="sr-only">Selected</span>
					</template>
					<template v-else>
						<span aria-hidden="true">&nbsp;</span>
						<span class="sr-only">Not selected</span>
					</template>
				</template>

				<template v-slot:cell(assetCode)="row">
					<span class="numFont">
						{{ row.item.assetCode }}
					</span>
				</template>
			</b-table>
		</b-row>
		<b-row>
			<b-col lg="6" md="12" sm="12" class="mt-1 text-sm-right">
				<b-input-group prepend="Show" append="/ Page" size="sm">
					<b-form-select :options="pageOptions" v-model="perPage" />
				</b-input-group>
			</b-col>
			<b-col lg="6" md="12" sm="12" class="mt-1">
				<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage" size="sm" />
			</b-col>
		</b-row>
	</div>
</template>

<script>
// Others
import EventBus from '@/shared/event-bus';
import _ from 'lodash';

export default {
	name: 'selected-asset-dispatch-table',
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'selected',
					label: '#',
					sortable: false,
				},
				{
					key: 'assetCode',
					label: 'Code',
					sortable: true,
				},
				{
					key: 'assetType',
					label: 'Type',
					sortable: true,
				},
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			allAssetsOptions: {},

			// for selected fields
			selected: {},
		};
	},
	mounted() {
		EventBus.$on('initSelectedAssets', (param) => {
			let selAssetsIds = param.selAssetIds;
			let assetsObj = param.assetsObj;
			this.initSelectedAssets(assetsObj, selAssetsIds);
		});

		EventBus.$on('addSelectedAssets', (selAssetsArr) => {
			let newAssets = selAssetsArr;
			newAssets.forEach((asset) => {
				// not yet part of selected assets
				if (!this.containsAsset(asset, this.items)) {
					this.items.push(asset);
					// have a backup object that will contain all objects
					this.allAssetsOptions[asset.assetCode] = asset;
				}
			});
			this.totalRows = this.items.length;
			this.$emit('onAddDispatchAssets', selAssetsArr);
		});

		EventBus.$on('resetSelectedAssetList', () => {
			this.allAssetsOptions = {};
			this.items = [];
			this.totalRows = this.items.length;
		});
	},
	methods: {
		onRowSelected(items) {
			this.selected = items;
		},
		selectAllRows() {
			this.$refs.selectedAssetsTable.selectAllRows();
		},
		clearSelected() {
			this.$refs.selectedAssetsTable.clearSelected();
		},
		initSelectedAssets(results, selAssetsIds) {
			let vm = this;

			this.allAssetsOptions = {};
			this.items = [];
			selAssetsIds.forEach((assetId) => {
				let currAsset = results[assetId];
				if (currAsset) {
					let asset = {
						id: currAsset.assetCode,
						assetCode: currAsset.assetCode,
						assetType: currAsset.assetType,
						assetTypeId: currAsset.assetTypeId,
						company: currAsset.currCompany,
						companyId: currAsset.currCompanyId,
					};

					vm.items.push(asset);
					// have a backup object that will contain all objects
					vm.allAssetsOptions[asset.assetCode] = asset;
				}
			});

			this.totalRows = this.items.length;
		},
		containsAsset(obj, list) {
			for (const element of list) {
				if (element['assetCode'] === obj['assetCode']) {
					return true;
				}
			}
			return false;
		},
		deleteSelectedAssets() {
			if (!_.isEmpty(this.selected)) {
				let tickedRows = Object.values(this.selected);
				tickedRows.forEach((tickedRow) => {
					let assetCode = tickedRow.assetCode;
					EventBus.$emit('onDeleteAsset', this.allAssetsOptions[assetCode]);
					delete this.allAssetsOptions[assetCode];
				});

				let deletedAssetCodesArr = _.map(tickedRows, 'assetCode');
				// emit event to update the assets on the dispatch form
				this.$emit('onDeleteAssets', deletedAssetCodesArr);

				this.items = Object.values(this.allAssetsOptions);
				this.totalRows = this.items.length;
			} else {
				this.$toaster.warning('Please select the assets you want to delete.');
			}
		},
		beforeDestroy() {
			EventBus.$off('initSelectedAssets');
			EventBus.$off('addSelectedAssets');
			EventBus.$off('resetSelectedAssetList');
		},
	},
};
</script>

<style scoped>
.selected-table {
	margin-top: 72px !important;
}
</style>

